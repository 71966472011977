html.overlay {
  height: 100%;
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
}

.overlay #animate {
  height: 100vh;
  overflow: hidden;
}
